import Constant from '@/const';

const axios = require('axios');
const _ = require('lodash');
const moment = require('moment');

const state = {
  form: {
    nurse: {
      subjective: {
        primary_complaint: '',
        anamnesis_type: 'autoanamnesis',
        complaint_by: '',
        disease_history: [''],
        is_acute: true,
        has_drug_history: false,
        drug_history: [
          {
            drugname: '',
            dosage: '',
            given_at: '',
          },
        ],
        has_allergy: null,
        alergic_history: [{}],
        has_psychospiritual_history: false,
        psychospiritual_histories: [''],
      },
      objective: {
        general_condition: '',
        ttv: {
          sistole: null,
          diastole: null,
          temperature: null,
          pulse: null,
          RR: null,
          saturation: null,
          weight: null,
          height: null,
          gds: null,
        },
        functional_status: {
          used_tool: 'Tidak',
          disability: null,
          disabilities: [''],
        },
        pain_assesment: {
          score: 0,
          provocation: [],
          other_provocation: '',
          quality: [],
          other_quality: '',
          radiation: null,
          severity: '',
          time: {
            type: '',
            duration: '',
          },
        },
        risk_assessment_falls: {
          age_type: '',
          age: null,
          totalScore: null,
          children: {
            diagnosis: 0,
            otherDiagnosis: '',
            cognitiveImpairment: 0,
            environmentFactor: 0,
            responseToAnesthesia: 0,
            drugUsed: 0,
          },
          adult: {
            isBalanced: false,
            toolsHelper: false,
          },
        },
        nutrition_screening: {
          weight_loss: null,
          reducedportion: null,
        },
        diagnosis: '',
        nurseplan: [{ diagnose: '', plans: [''] }],
      },
    },
    doctor: {
      anamnesis: '',
      general_condition: '',
      ttv: {
        sistole: null,
        diastole: null,
        temperature: null,
        pulse: null,
        RR: null,
        saturation: null,
        weight: null,
        height: null,
        gds: null,
      },
      physical_checkup: {
        head: '',
        hair: '',
        eyes: '',
        ear: '',
        nose: '',
        mouth: '',
        neck: '',
        chest: '',
        breast: '',
        back: '',
        abdomen: '',
        upper_extremity: '',
        lower_extremity: '',
        genitalia: '',
        anus: '',
        localist_status: '',
        generalist_status: '',
      },
      other_exam_result: '',
      diagnostic: [{}],
      follow_up: [{}],
      action_plan: '',
      inpatient_instruction: '',
      return_status: {
        type: 0,
        internalReferralClinics: [],
        externalReferralClinics: '',
        externalReferralHospital: '',
      },
    },
    screenTB: {
      screeningId: '',
      createdBy: '',
      createdAt: '',
      note: '',
      adultScreening: {
        isCoughing: false,
        coughExceedsTwoWeeks: false,
        hasHemoptysis: false,
        hasDispnea: false,
        hasFever: false,
        weightLoss: false,
        radiologySuspected: false,
      },
      childScreening: {
        isCoughing: 0,
        hasFever: 0,
        patient_contact: 0,
        hasLymphadenitis: 0,
        hasArthritis: 0,
        radiologySuspected: 0,
        mantouxTest: 0,
        weight_loss: 0,
      },
    },
  },
  initialForm: {
    nurse: {
      subjective: {
        primary_complaint: '',
        anamnesis_type: 'autoanamnesis',
        complaint_by: '',
        disease_history: [''],
        is_acute: true,
        has_drug_history: false,
        drug_history: [
          {
            drugname: '',
            dosage: '',
            given_at: '',
          },
        ],
        has_allergy: null,
        alergic_history: [{}],
        has_psychospiritual_history: false,
        psychospiritual_histories: [''],
      },
      objective: {
        general_condition: '',
        ttv: {
          sistole: null,
          diastole: null,
          temperature: null,
          pulse: null,
          RR: null,
          saturation: null,
          weight: null,
          height: null,
          gds: null,
        },
        functional_status: {
          used_tool: 'Tidak',
          disability: null,
          disabilities: [''],
        },
        pain_assesment: {
          score: 0,
          provocation: [],
          other_provocation: '',
          quality: [],
          other_quality: '',
          radiation: null,
          severity: '',
          time: {
            type: '',
            duration: '',
          },
        },
        risk_assessment_falls: {
          age_type: '',
          age: null,
          totalScore: null,
          children: {
            diagnosis: 0,
            otherDiagnosis: '',
            cognitiveImpairment: 0,
            environmentFactor: 0,
            responseToAnesthesia: 0,
            drugUsed: 0,
          },
          adult: {
            isBalanced: false,
            toolsHelper: false,
          },
        },
        nutrition_screening: {
          weight_loss: null,
          reducedportion: null,
        },
        diagnosis: '',
        nurseplan: [{ diagnose: '', plans: [''] }],
      },
    },
    doctor: {
      anamnesis: '',
      general_condition: '',
      ttv: {
        sistole: null,
        diastole: null,
        temperature: null,
        pulse: null,
        RR: null,
        saturation: null,
        weight: null,
        height: null,
        gds: null,
      },
      physical_checkup: {
        head: '',
        hair: '',
        eyes: '',
        ear: '',
        nose: '',
        mouth: '',
        neck: '',
        chest: '',
        breast: '',
        back: '',
        abdomen: '',
        upper_extremity: '',
        lower_extremity: '',
        genitalia: '',
        anus: '',
        localist_status: '',
        generalist_status: '',
      },
      other_exam_result: '',
      diagnostic: [{}],
      follow_up: [{}],
      action_plan: '',
      inpatient_instruction: '',
      return_status: {
        type: 0,
        internalReferralClinics: [],
        externalReferralClinics: '',
        externalReferralHospital: '',
      },
    },
  },
  items: {
    dataNurseDiagnose: {
      isLoading: false,
      data: [],
    },
    dataNursePlan: {
      isLoading: false,
      data: [],
    },
    dataDiagnosis: {
      isLoading: false,
      data: [],
    },
    dataProcedure: {
      isLoading: false,
      data: [],
    },
    dataUnit: {
      isLoading: false,
      data: [],
    },
  },
  snackBar: {
    isShow: false,
    text: '',
    color: '#2B9C4D',
  },
  isLoading: {
    screenTB: false,
  },
  isSaved: {
    byNurse: false,
    byDoctor: false,
  },
};

const getters = {
  getForm: (state) => state.form,
  getItems: (state) => state.items,
  getIsEqual: (state) => {
    return (
      _.isEqual(state.initialForm.doctor, state.form.doctor) &&
      _.isEqual(state.initialForm.nurse, state.form.nurse)
    );
  },
  getSnackbar: (state) => state.snackBar,
  getIsLoading: (state) => state.isLoading,
  getIsSaved: (state) => state.isSaved,
};

const mutations = {
  setForm(state, payload) {
    state.form = payload;
  },
  setInitialForm(state, payload) {
    state.initialForm = payload;
  },
  setAllergyHistory(state, payload) {
    state.form.nurse.subjective.alergic_history = payload;
  },
  setHasAllergyHistory(state, payload) {
    state.form.nurse.subjective.has_allergy = payload;
  },
  clearForm(state) {
    state.form = {
      nurse: {
        subjective: {
          primary_complaint: '',
          anamnesis_type: 'autoanamnesis',
          complaint_by: '',
          disease_history: [''],
          is_acute: true,
          has_drug_history: false,
          drug_history: [
            {
              drugname: '',
              dosage: '',
              given_at: '',
            },
          ],
          has_allergy: null,
          alergic_history: [{}],
          has_psychospiritual_history: false,
          psychospiritual_histories: [''],
        },
        objective: {
          general_condition: '',
          ttv: {
            sistole: null,
            diastole: null,
            temperature: null,
            pulse: null,
            RR: null,
            saturation: null,
            weight: null,
            height: null,
            gds: null,
          },
          functional_status: {
            used_tool: 'Tidak',
            disability: null,
            disabilities: [''],
          },
          pain_assesment: {
            score: 0,
            provocation: [],
            other_provocation: '',
            quality: [],
            other_quality: '',
            radiation: null,
            severity: '',
            time: {
              type: '',
              duration: '',
            },
          },
          risk_assessment_falls: {
            age_type: '',
            age: null,
            totalScore: null,
            children: {
              diagnosis: 0,
              otherDiagnosis: '',
              cognitiveImpairment: 0,
              environmentFactor: 0,
              responseToAnesthesia: 0,
              drugUsed: 0,
            },
            adult: {
              isBalanced: false,
              toolsHelper: false,
            },
          },
          nutrition_screening: {
            weight_loss: null,
            reducedportion: null,
          },
          diagnosis: '',
          nurseplan: [{ diagnose: '', plans: [''] }],
        },
      },
      doctor: {
        anamnesis: '',
        general_condition: '',
        ttv: {
          sistole: null,
          diastole: null,
          temperature: null,
          pulse: null,
          RR: null,
          saturation: null,
          weight: null,
          height: null,
          gds: null,
        },
        physical_checkup: {
          head: '',
          hair: '',
          eyes: '',
          ear: '',
          nose: '',
          mouth: '',
          neck: '',
          chest: '',
          breast: '',
          back: '',
          abdomen: '',
          upper_extremity: '',
          lower_extremity: '',
          genitalia: '',
          anus: '',
          localist_status: '',
          generalist_status: '',
        },
        other_exam_result: '',
        diagnostic: [{}],
        follow_up: [{}],
        action_plan: '',
        inpatient_instruction: '',
        return_status: {
          type: 0,
          internalReferralClinics: [],
          externalReferralClinics: '',
          externalReferralHospital: '',
        },
      },
      screenTB: {
        note: '',
        adultScreening: {
          isCoughing: false,
          coughExceedsTwoWeeks: false,
          hasHemoptysis: false,
          hasDispnea: false,
          hasFever: false,
          weightLoss: false,
          radiologySuspected: false,
        },
        childScreening: {
          isCoughing: 0,
          hasFever: 0,
          patient_contact: 0,
          hasLymphadenitis: 0,
          hasArthritis: 0,
          radiologySuspected: 0,
          mantouxTest: 0,
          weight_loss: 0,
        },
      },
    };

    state.initialForm = {
      nurse: {
        subjective: {
          primary_complaint: '',
          anamnesis_type: 'autoanamnesis',
          complaint_by: '',
          disease_history: [''],
          is_acute: true,
          has_drug_history: false,
          drug_history: [
            {
              drugname: '',
              dosage: '',
              given_at: '',
            },
          ],
          has_allergy: null,
          alergic_history: [{}],
          has_psychospiritual_history: false,
          psychospiritual_histories: [''],
        },
        objective: {
          general_condition: '',
          ttv: {
            sistole: null,
            diastole: null,
            temperature: null,
            pulse: null,
            RR: null,
            saturation: null,
            weight: null,
            height: null,
            gds: null,
          },
          functional_status: {
            used_tool: 'Tidak',
            disability: null,
            disabilities: [''],
          },
          pain_assesment: {
            score: 0,
            provocation: [],
            other_provocation: '',
            quality: [],
            other_quality: '',
            radiation: null,
            severity: '',
            time: {
              type: '',
              duration: '',
            },
          },
          risk_assessment_falls: {
            age_type: '',
            age: null,
            totalScore: null,
            children: {
              diagnosis: 0,
              otherDiagnosis: '',
              cognitiveImpairment: 0,
              environmentFactor: 0,
              responseToAnesthesia: 0,
              drugUsed: 0,
            },
            adult: {
              isBalanced: false,
              toolsHelper: false,
            },
          },
          nutrition_screening: {
            weight_loss: null,
            reducedportion: null,
          },
          diagnosis: '',
          nurseplan: [{ diagnose: '', plans: [''] }],
        },
      },
      doctor: {
        anamnesis: '',
        general_condition: '',
        ttv: {
          sistole: null,
          diastole: null,
          temperature: null,
          pulse: null,
          RR: null,
          saturation: null,
          weight: null,
          height: null,
          gds: null,
        },
        physical_checkup: {
          head: '',
          hair: '',
          eyes: '',
          ear: '',
          nose: '',
          mouth: '',
          neck: '',
          chest: '',
          breast: '',
          back: '',
          abdomen: '',
          upper_extremity: '',
          lower_extremity: '',
          genitalia: '',
          anus: '',
          localist_status: '',
          generalist_status: '',
        },
        other_exam_result: '',
        diagnostic: [{}],
        follow_up: [{}],
        action_plan: '',
        inpatient_instruction: '',
        return_status: {
          type: 0,
          internalReferralClinics: [],
          externalReferralClinics: '',
          externalReferralHospital: '',
        },
      },
    };

    state.snackBar = {
      isShow: false,
      text: '',
      color: '#2B9C4D',
    };
  },
  setItems(state, payload) {
    const { label, value, isLoading } = payload;
    state.items[label] = {
      ...state[label],
      data: state.items[label].data.concat(value),
      isLoading,
    };
  },
  setSnackbar(state, payload) {
    state.snackBar = payload;
  },
};

const actions = {
  async resolveGetUnit({ commit }) {
    let clinic = [];
    await axios
      .get(
        Constant.apiUrl.concat(
          '/masterdata/unit/category/Instalasi Rawat Jalan',
        ),
      )
      .then((response) => {
        const { data } = response.data;
        clinic = data.map((clinic) => {
          return {
            text: clinic.detail.name,
            id: clinic._id,
            unitNumber: clinic.unit_number,
          };
        });
      });

    await axios
      .get(
        Constant.apiUrl.concat(
          '/masterdata/unit?page=1&itemCount=8&search=Instalasi Gawat Darurat&sort=-detail.name',
        ),
      )
      .then((response) => {
        const { data } = response.data;
        const igd = data.map((clinic) => {
          return {
            text: clinic.detail.name,
            id: clinic._id,
            unitNumber: clinic.unit_number,
          };
        });

        clinic.push(igd[0]);
      })
      .catch(() => {
        clinic = [];
      });
    await axios
      .get(
        Constant.apiUrl.concat(
          '/masterdata/unit/category/Instalasi Rawat Inap',
        ),
      )
      .then((response) => {
        const { data } = response.data;
        const ranap = data.map((clinic) => {
          return {
            text: clinic.detail.name,
            id: clinic._id,
            unitNumber: clinic.unit_number,
          };
        });
        ranap.forEach(async (element) => {
          await clinic.push(element);
        });
      });
    commit('setItems', {
      label: 'dataUnit',
      value: clinic,
      isLoading: false,
    });
  },
  async resolveGetAssessment({ commit, state }, { patientData, polyId }) {
    const removeCodeDescription = (code, description) => {
      if (description && code) {
        return description.replace(code, '').trim().replace(/^-/, '').trim();
      }
      return description;
    };
    try {
      const response = await axios.get(
        Constant.apiUrl.concat(
          `/outpatient/assessment/${
            patientData.id_unit || polyId
          }?id_registration=${patientData.id_queue}&id_patient=${
            patientData.id
          }`,
        ),
      );
      const dataNurse = response.data.data.nursery
        ? response.data.data.nursery
        : state.form.nurse;
      const dataDoctor = response.data.data.doctor;
      const isFilledByDoctor =
        dataDoctor && dataDoctor.created_by ? dataDoctor.created_by : null;

      state.isSaved.byNurse =
        !!dataNurse.created_at && !response.data.data.isAutofill;
      state.isSaved.byDoctor = !dataDoctor.isAutofill;

      const mapResponse = {
        ...state.form,
        ...response.data.data,
        nurse: {
          ...dataNurse,
          subjective: {
            ...dataNurse.subjective,
            complaint_by: dataNurse.subjective.complaint_by,
            alergic_history: !dataNurse.subjective.has_allergy
              ? [{}]
              : dataNurse.subjective.alergic_history,
          },
          objective: {
            ...dataNurse.objective,
            nurseplan:
              dataNurse.objective.nurseplan.length === 0
                ? [{ diagnose: '', plans: [''] }]
                : dataNurse.objective.nurseplan.reduce((group, current) => {
                    const temp = group.find(
                      (grp) => grp.diagnose === current.diagnose,
                    );
                    const plan = {
                      text: current.plan,
                      diagnose: current.diagnose,
                      diagnose_number: current.diagnose_number,
                      plan_number: current.plan_number,
                      id_plan: current._id,
                    };
                    if (temp)
                      return [
                        ...group.filter(
                          (grp) => grp.diagnose !== current.diagnose,
                        ),
                        {
                          ...temp,
                          plans: temp.plans.concat(plan),
                        },
                      ];
                    return [
                      ...group,
                      {
                        diagnose: current.diagnose,
                        plans: [plan],
                      },
                    ];
                  }, []),
            ttv: {
              sistole: dataNurse.objective.ttv.sistole
                ? dataNurse.objective.ttv.sistole.toString()
                : null,
              diastole: dataNurse.objective.ttv.diastole
                ? dataNurse.objective.ttv.diastole.toString()
                : null,
              temperature: dataNurse.objective.ttv.temperature
                ? dataNurse.objective.ttv.temperature.toString()
                : null,
              pulse: dataNurse.objective.ttv.pulse
                ? dataNurse.objective.ttv.pulse.toString()
                : null,
              RR: dataNurse.objective.ttv.RR
                ? dataNurse.objective.ttv.RR.toString()
                : null,
              saturation: dataNurse.objective.ttv.saturation
                ? dataNurse.objective.ttv.saturation.toString()
                : null,
              weight: dataNurse.objective.ttv.weight
                ? dataNurse.objective.ttv.weight.toString()
                : null,
              height: dataNurse.objective.ttv.height
                ? dataNurse.objective.ttv.height.toString()
                : null,
              gds: dataNurse.objective.ttv.gds
                ? dataNurse.objective.ttv.gds.toString()
                : null,
            },
          },
        },
        doctor: !isFilledByDoctor
          ? {
              ...state.form.doctor,
              ttv: {
                sistole: response.data.data.ttv.sistole
                  ? response.data.data.ttv.sistole.toString()
                  : null,
                diastole: response.data.data.ttv.diastole
                  ? response.data.data.ttv.diastole.toString()
                  : null,
                temperature: response.data.data.ttv.temperature
                  ? response.data.data.ttv.temperature.toString()
                  : null,
                pulse: response.data.data.ttv.pulse
                  ? response.data.data.ttv.pulse.toString()
                  : null,
                RR: response.data.data.ttv.RR
                  ? response.data.data.ttv.RR.toString()
                  : null,
                saturation: response.data.data.ttv.saturation
                  ? response.data.data.ttv.saturation.toString()
                  : null,
                weight: response.data.data.ttv.weight
                  ? response.data.data.ttv.weight.toString()
                  : null,
                height: response.data.data.ttv.height
                  ? response.data.data.ttv.height.toString()
                  : null,
                gds: response.data.data.ttv.gds
                  ? response.data.data.ttv.gds.toString()
                  : null,
              },
              anamnesis: dataNurse.subjective.primary_complaint,
              general_condition: dataNurse.objective.general_condition,
            }
          : {
              ...dataDoctor,
              diagnostic:
                dataDoctor.diagnostic.length === 0
                  ? [{}]
                  : dataDoctor.diagnostic.map((val) => {
                      if (val === '') {
                        return {};
                      }
                      return {
                        ...val,
                      };
                    }),
              follow_up:
                dataDoctor.follow_up.length === 0
                  ? [{}]
                  : dataDoctor.follow_up.map((val) => {
                      if (val === '') {
                        return {};
                      }
                      return {
                        ...val,
                      };
                    }),
            },
      };
      commit('setItems', {
        label: 'dataDiagnosis',
        value: dataDoctor.diagnostic
          .filter((val) => val.value)
          .map((val) => {
            return {
              ...val,
              textDisplay: `${val.value}-${removeCodeDescription(
                val.value,
                val.text,
              )}`,
            };
          }),
        isLoading: false,
      });
      commit('setItems', {
        label: 'dataProcedure',
        value: dataDoctor.follow_up
          .filter((val) => val.value)
          .map((val) => {
            return {
              ...val,
              textDisplay: `${val.value}-${removeCodeDescription(
                val.value,
                val.text,
              )}`,
            };
          }),
        isLoading: false,
      });
      const cloneMapResponse = _.cloneDeep(mapResponse);
      commit('setForm', mapResponse);
      commit('setInitialForm', cloneMapResponse);
    } catch (error) {
      console.error(error);
      state.isSaved.byNurse = false;
      state.isSaved.byDoctor = false;
      const res = await axios.get(
        Constant.apiUrl.concat(
          `/patientEMR/getPatientAllergy/${patientData.id}`,
        ),
      );
      const { data } = res.data;
      const temp = data.filter((arr) => arr);
      const noDupe = temp.filter((e, i) => temp.indexOf(e) === i);
      commit('setHasAllergyHistory', noDupe.length !== 0);
      commit(
        'setAllergyHistory',
        noDupe.length !== 0 ? noDupe.map((item) => ({ allergen: item })) : [{}],
      );
      commit('setForm', {
        ...state.form,
        nurse: {
          ...state.form.nurse,
          subjective: {
            ...state.form.nurse.subjective,
            complaint_by: '',
          },
        },
      });
      commit('setInitialForm', {
        ...state.form,
        nurse: {
          ...state.form.nurse,
          subjective: {
            ...state.form.nurse.subjective,
            complaint_by: '',
          },
        },
      });
    }
  },

  async resolveGetNurseDiagnosePlan({ commit }) {
    commit('setItems', {
      label: 'dataNurseDiagnose',
      value: [],
      isLoading: false,
    });
    commit('setItems', {
      label: 'dataNursePlan',
      value: [],
      isLoading: true,
    });
    try {
      const response = await axios.get(
        Constant.apiUrl.concat('/masterdata/nurseplan?type_plan=noplan'),
      );
      const diagnosis = response.data.data
        .filter(
          (arr, index) =>
            index ===
            response.data.data.findIndex((ar) => ar.diagnose === arr.diagnose),
        )
        .map((arr) => ({
          text: arr.diagnose,
          diagnose: arr.diagnose,
          diagnose_number: arr.diagnose_number,
        }));
      commit('setItems', {
        label: 'dataNurseDiagnose',
        value: diagnosis,
        isLoading: false,
      });
      const plans = response.data.data.map((val) => {
        return {
          text: val.plan,
          diagnose: val.diagnose,
          diagnose_number: val.diagnose_number,
          plan_number: val.plan_number,
          id_plan: val._id,
          is_additional_data: _.get(val, 'allowAdditionalData', false),
        };
      });
      commit('setItems', {
        label: 'dataNursePlan',
        value: plans,
        isLoading: false,
      });
    } catch {
      commit('setItems', {
        label: 'dataNurseDiagnose',
        value: [],
        isLoading: false,
      });
      commit('setItems', {
        label: 'dataNursePlan',
        value: [],
        isLoading: false,
      });
    }
  },

  async resolveGetDiagnose({ commit }, { keyword }) {
    commit('setItems', {
      label: 'dataDiagnosis',
      value: [],
      isLoading: true,
    });
    try {
      const response = await axios.get(
        Constant.apiUrl
          .concat(
            '/masterdata/diagnose?page=1&itemCount=25&sort=description&search=',
          )
          .concat(keyword !== null ? keyword : ''),
      );
      const mapResponse = response.data.data.map((item) => {
        return {
          textDisplay: `${item.code}-${item.description}`,
          diagnose_id: item._id.toString(),
          text: item.description,
          value: item.code,
        };
      });
      commit('setItems', {
        label: 'dataDiagnosis',
        value: mapResponse,
        isLoading: false,
      });
    } catch {
      commit('setItems', {
        label: 'dataDiagnosis',
        value: [],
        isLoading: false,
      });
    }
  },

  async resolveGetProcedure({ commit }, { keyword }) {
    commit('setItems', {
      label: 'dataProcedure',
      value: [],
      isLoading: true,
    });
    try {
      const response = await axios.get(
        Constant.apiUrl
          .concat(
            '/masterdata/procedure?page=1&itemCount=25&sort=description&search=',
          )
          .concat(keyword !== null ? keyword : ''),
      );
      const mapResponse = response.data.data.map((item) => {
        return {
          textDisplay: `${item.code}-${item.description}`,
          procedure_id: item._id.toString(),
          text: item.description,
          value: item.code,
        };
      });
      commit('setItems', {
        label: 'dataProcedure',
        value: mapResponse,
        isLoading: false,
      });
    } catch {
      commit('setItems', {
        label: 'dataProcedure',
        value: [],
        isLoading: false,
      });
    }
  },

  async resolveGetScreenTB({ commit, state }, { idQueue, idPoly }) {
    try {
      await axios
        .get(
          `${Constant.apiUrl}/patient/screening/tbc/byregist?id_registration=${idQueue}&id_poly=${idPoly}`,
        )
        .then((response) => {
          const { data } = response.data;
          commit('setForm', {
            ...state.form,
            screenTB: {
              screeningId: data._id,
              createdBy: data.screening.timestamps.created_by_name,
              createdAt: moment(data.screening.timestamps.created_at).format(
                'DD/MM/YYYY HH:mm [WIB]',
              ),
              note: data.screening.note,
              id_poly: data.id_poly,
              adultScreening: data.screening.adult_screening,
              childScreening: {
                ...state.form.screenTB.childScreening,
                isCoughing: data.screening.child_screening.isCoughing,
                hasFever: data.screening.child_screening.hasFever,
                patient_contact: data.screening.child_screening.patient_contact,
                hasLymphadenitis:
                  data.screening.child_screening.hasLymphadenitis,
                hasArthritis: data.screening.child_screening.hasArthritis,
                radiologySuspected:
                  data.screening.child_screening.radiologySuspected,
                mantouxTest: data.screening.child_screening.mantouxTest,
                weight_loss: data.screening.child_screening.weight_loss,
              },
              isAdult: data.screening.isAdult,
              created_by: data.screening.timestamps.created_by_name,
              created_at: data.screening.timestamps.created_at,
            },
          });
        });
    } catch (error) {
      console.log('error: ', error);
    }
  },

  async resolvePostAssessment(
    { state, dispatch, commit },
    {
      patientData,
      userLogin,
      isNurse,
      isCasemix,
      isFollowUpAssessment,
      polyId,
      isSaved,
    },
  ) {
    const nurseplan = [];
    state.form.nurse.objective.nurseplan.forEach((diagnose) => {
      diagnose.plans.forEach((plan) => {
        if (plan.id_plan) nurseplan.push(plan.id_plan);
      });
    });

    const scoreValue = () => {
      if (patientData.meta.age < 17) {
        return (
          Number(
            state.form.nurse.objective.risk_assessment_falls.children
              .diagnosis || 0,
          ) +
          Number(
            state.form.nurse.objective.risk_assessment_falls.children
              .cognitiveImpairment || 0,
          ) +
          Number(
            state.form.nurse.objective.risk_assessment_falls.children
              .environmentFactor || 0,
          ) +
          Number(
            state.form.nurse.objective.risk_assessment_falls.children
              .responseToAnesthesia || 0,
          ) +
          Number(
            state.form.nurse.objective.risk_assessment_falls.children
              .drugUsed || 0,
          )
        );
      }
      if (
        state.form.nurse.objective.risk_assessment_falls.adult.isBalanced ===
          true &&
        state.form.nurse.objective.risk_assessment_falls.adult.toolsHelper ===
          true
      ) {
        return 12;
      }
      if (
        state.form.nurse.objective.risk_assessment_falls.adult.isBalanced ===
          true ||
        state.form.nurse.objective.risk_assessment_falls.adult.toolsHelper ===
          true
      ) {
        return 10;
      }
      return 0;
    };

    const payloadNurse = {
      isFollowUpAssessment,
      detail_nursery: {
        ...state.form.nurse,
        subjective: {
          ...state.form.nurse.subjective,
          psychospiritual_histories: state.form.nurse.subjective
            .has_psychospiritual_history
            ? state.form.nurse.subjective.psychospiritual_histories
            : [''],
          drug_history: state.form.nurse.subjective.has_drug_history
            ? state.form.nurse.subjective.drug_history
            : [{}],
          alergic_history: state.form.nurse.subjective.has_allergy
            ? state.form.nurse.subjective.alergic_history
            : [{}],
        },
        objective: {
          ...state.form.nurse.objective,
          nurseplan,
          risk_assessment_falls: {
            ...state.form.nurse.objective.risk_assessment_falls,
            totalScore: scoreValue(),
            age: patientData.meta.age,
            age_type: patientData.meta.age > 17 ? 'adult' : 'children',
          },
          functional_status: {
            ...state.form.nurse.objective.functional_status,
            disabilities: state.form.nurse.objective.functional_status
              .disability
              ? state.form.nurse.objective.functional_status.disabilities
              : [''],
          },
          pain_assesment:
            state.form.nurse.objective.pain_assesment.score !== 0
              ? { ...state.form.nurse.objective.pain_assesment }
              : {
                  score: 0,
                  provocation: [],
                  other_provocation: '',
                  quality: [],
                  other_quality: '',
                  radiation: null,
                  severity: '',
                  time: {
                    type: '',
                    duration: '',
                  },
                },
        },
        timestamps: {
          created_by: userLogin.id,
        },
      },
    };
    if (userLogin.role.primary.toLowerCase() === 'casemix') {
      payloadNurse.detail_nursery = {
        ...payloadNurse.detail_nursery,
        timestamps: {
          created_by: state.form.nurse.created_by_id,
          created_at: state.form.nurse.created_at,
          corrected_by: userLogin.id,
          corrected_at: state.form.nurse.corrected_at,
        },
      };
    }

    const payloadDoctor = {
      isFollowUpAssessment,
      detail_doctor: {
        ...state.form.doctor,
        physical_checkup: {
          head: state.form.doctor.physical_checkup.head || 'Normal',
          hair: state.form.doctor.physical_checkup.hair || 'Normal',
          eyes: state.form.doctor.physical_checkup.eyes || 'Normal',
          ear: state.form.doctor.physical_checkup.ear || 'Normal',
          nose: state.form.doctor.physical_checkup.nose || 'Normal',
          mouth: state.form.doctor.physical_checkup.mouth || 'Normal',
          neck: state.form.doctor.physical_checkup.neck || 'Normal',
          chest: state.form.doctor.physical_checkup.chest || 'Normal',
          breast: state.form.doctor.physical_checkup.breast || 'Normal',
          back: state.form.doctor.physical_checkup.back || 'Normal',
          abdomen: state.form.doctor.physical_checkup.abdomen || 'Normal',
          upper_extremity:
            state.form.doctor.physical_checkup.upper_extremity || 'Normal',
          lower_extremity:
            state.form.doctor.physical_checkup.lower_extremity || 'Normal',
          genitalia: state.form.doctor.physical_checkup.genitalia || 'Normal',
          anus: state.form.doctor.physical_checkup.anus || 'Normal',
          localist_status: state.form.doctor.physical_checkup.localist_status,
          generalist_status:
            state.form.doctor.physical_checkup.generalist_status,
        },
        timestamps: {
          created_by: userLogin.id,
        },
      },
    };
    if (userLogin.role.primary.toLowerCase() === 'casemix') {
      payloadDoctor.detail_doctor = {
        ...payloadDoctor.detail_doctor,
        timestamps: {
          created_by: state.form.doctor.created_by_id,
          created_at: state.form.doctor.created_at,
          corrected_by: userLogin.id,
          corrected_at: state.form.doctor.corrected_at,
        },
      };
    }

    if (isCasemix) {
      try {
        const responseNurse = await axios.post(
          Constant.apiUrl.concat(
            `/outpatient/assessment/${
              patientData.id_unit || polyId
            }?id_registration=${patientData.id_queue}&id_patient=${
              patientData.id
            }&filled_by=nurse`,
          ),
          payloadNurse,
        );
        const responseDoctor = await axios.post(
          Constant.apiUrl.concat(
            `/outpatient/assessment/${
              patientData.id_unit || polyId
            }?id_registration=${patientData.id_queue}&id_patient=${
              patientData.id
            }&filled_by=doctor`,
          ),
          payloadDoctor,
        );
        dispatch('resolveGetAssessment', { patientData, polyId });
        // eslint-disable-next-line no-nested-ternary
        const responseTemp =
          // eslint-disable-next-line no-nested-ternary
          responseNurse.status === 200 && responseDoctor.status === 200
            ? {
                code: 200,
                message: 'Data berhasil disimpan',
              }
            : responseNurse.status === 200 && responseDoctor.status !== 200
            ? {
                code: responseDoctor.status,
                message: 'Data dokter gagal disimpan',
              }
            : {
                code: responseNurse.status,
                message: 'Data perawat gagal disimpan',
              };
        commit('setSnackbar', {
          isShow: true,
          text: isSaved
            ? 'Asesmen berhasil diubah'
            : 'Asesmen berhasil disimpan',
          color: '#2B9C4D',
        });
        setTimeout(() => {
          commit('setSnackbar', {
            isShow: false,
            text: '',
            color: '#2B9C4D',
          });
        }, 3000);
        return responseTemp;
      } catch (error) {
        commit('setSnackbar', {
          isShow: true,
          text: 'Asesmen gagal disimpan',
          color: '#D32F2F',
        });
        setTimeout(() => {
          commit('setSnackbar', {
            isShow: false,
            text: '',
            color: '#D32F2F',
          });
        }, 3000);
        return error;
      }
    } else {
      try {
        const response = await axios.post(
          Constant.apiUrl.concat(
            `/outpatient/assessment/${
              patientData.id_unit || polyId
            }?id_registration=${patientData.id_queue}&id_patient=${
              patientData.id
            }&filled_by=${isNurse ? 'nurse' : 'doctor'}`,
          ),
          isNurse ? payloadNurse : payloadDoctor,
        );
        dispatch('resolveGetAssessment', { patientData, polyId });
        commit('setSnackbar', {
          isShow: true,
          text: isSaved
            ? 'Asesmen berhasil diubah'
            : 'Asesmen berhasil disimpan',
          color: '#2B9C4D',
        });
        setTimeout(() => {
          commit('setSnackbar', {
            isShow: false,
            text: '',
            color: '#2B9C4D',
          });
        }, 3000);
        return response.status;
      } catch (error) {
        commit('setSnackbar', {
          isShow: true,
          text: 'Asesmen gagal disimpan',
          color: '#D32F2F',
        });
        setTimeout(() => {
          commit('setSnackbar', {
            isShow: false,
            text: '',
            color: '#D32F2F',
          });
        }, 3000);
        return error;
      }
    }
  },

  async resolvePostScreenTB(
    { commit, state, dispatch },
    {
      idPatient,
      idQueue,
      idPoly,
      patientStatus,
      idTriase,
      createdBy,
      isAdult,
      score,
      isSuspected,
    },
  ) {
    state.isLoading.screenTB = true;
    try {
      const payload = {
        id_registration: idQueue,
        id_poly: idPoly,
        patient_status: patientStatus,
        id_triase: idTriase,
        screening: [
          {
            note: state.form.screenTB.note,
            isAdult,
            child_screening: state.form.screenTB.childScreening,
            adult_screening: state.form.screenTB.adultScreening,
            score,
            isSuspected,
            timestamps: {
              created_by: createdBy,
            },
          },
        ],
        timestamps: { created_by: createdBy },
      };
      const response = await axios.post(
        `${Constant.apiUrl}/patient/screening/tbc/${idPatient}`,
        payload,
      );
      if (response) {
        dispatch('resolveGetScreenTB', {
          idQueue,
          idPoly,
        });
        commit('setSnackbar', {
          isShow: true,
          text: 'Skrining TB berhasil disimpan',
          color: '#2B9C4D',
        });
        setTimeout(() => {
          commit('setSnackbar', {
            isShow: false,
            text: '',
            color: '#2B9C4D',
          });
        }, 3000);
      }
    } catch (error) {
      commit('setSnackbar', {
        isShow: true,
        text: 'Skrining TB gagal disimpan',
        color: '#D32F2F',
      });
      setTimeout(() => {
        commit('setSnackbar', {
          isShow: false,
          text: '',
          color: '#2B9C4D',
        });
      }, 3000);
      console.log('error:', error);
    } finally {
      state.isLoading.screenTB = false;
    }
  },

  async resolvePutScreenTB(
    { commit, state, dispatch },
    { idQueue, idPoly, createdBy, isAdult, score, isSuspected },
  ) {
    state.isLoading.screenTB = true;
    try {
      const payload = {
        note: state.form.screenTB.note,
        isAdult,
        child_screening: state.form.screenTB.childScreening,
        adult_screening: state.form.screenTB.adultScreening,
        score,
        isSuspected,
        timestamps: {
          created_by: createdBy,
        },
      };
      const response = await axios.put(
        `${Constant.apiUrl}/patient/screening/tbc/${state.form.screenTB.screeningId}`,
        payload,
      );
      if (response) {
        dispatch('resolveGetScreenTB', {
          idQueue,
          idPoly,
        });
        commit('setSnackbar', {
          isShow: true,
          text: 'Skrining TB berhasil diubah',
          color: '#2B9C4D',
        });
        setTimeout(() => {
          commit('setSnackbar', {
            isShow: false,
            text: '',
            color: '#2B9C4D',
          });
        }, 3000);
      }
    } catch (error) {
      commit('setSnackbar', {
        isShow: true,
        text: 'Skrining TB gagal diubah',
        color: '#D32F2F',
      });
      setTimeout(() => {
        commit('setSnackbar', {
          isShow: false,
          text: '',
          color: '#2B9C4D',
        });
      }, 3000);
      console.log('error:', error);
    } finally {
      state.isLoading.screenTB = false;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
