import Constant from '@/const';

const axios = require('axios');

const state = {
  history: {
    cppt: {
      isLoading: false,
      data: [],
    },
  },
};

const getters = {
  getHistory: (state) => state.history,
};

const mutations = {
  setHistory(state, payload) {
    state.history[payload.key] = payload.value;
  },
};

const actions = {
  async resolveGetHistoryCPPT({ commit }, payload) {
    commit('setHistory', {
      key: 'cppt',
      value: {
        isLoading: true,
        data: [],
      },
    });
    try {
      const response = await axios.get(
        `${Constant.apiUrl}/inpatient/emr/cppt/${payload.id}/${payload.id_queue}`,
      );
      commit('setHistory', {
        key: 'cppt',
        value: {
          isLoading: false,
          data: response.data.data,
        },
      });
      return response.data.data;
    } catch (error) {
      commit('setHistory', {
        key: 'cppt',
        value: {
          isLoading: false,
          data: [],
        },
      });
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
