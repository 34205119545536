// import Constant from '@/const';

// const axios = require('axios');

export default {
  namespaced: true,
  state: {
    drugReturns: null,
    drugToolsRequest: null,
    // drugToolsRequest: {
    //   drugRequest: null,
    //   medicalDemand: null,
    //   drugReview: null,
    // },
    drugHandover: null,
    drugCreate: null,
    detailOrder: null,
  },
  getters: {
    drugReturns: (state) => state.drugReturns,
    drugToolsRequest: (state) => state.drugToolsRequest,
    drugReview: (state) => state.drugReview,
    drugHandover: (state) => state.drugHandover,
    drugCreate: (state) => state.drugCreate,
    getModuleState(state, getters, rootState) {
      return rootState.orders;
    },
  },
  mutations: {
    removeDrugReturns(state) {
      state.drugReturns = null;
    },
    removeDrugHandover(state) {
      state.drugHandover = null;
    },
    removeDrugCreate(state) {
      state.drugCreate = null;
    },
    removeDrugToolsRequest(state) {
      // state.drugToolsRequest = {
      //   drugRequest: null,
      //   medicalDemand: null,
      //   drugReview: null,
      // };
      state.drugToolsRequest = null;
    },
    removeDrugOrder(state) {
      state.detailOrder = null;
    },
    saveData(state, payload) {
      if (payload.section === '') {
        state[payload.key] = payload.data;
      } else {
        state[payload.key][payload.section] = payload.data;
      }
    },
    updatePayload(state, payload) {
      console.log(payload);
      state.detailOrder = payload;
    },
    setData(state, payload) {
      console.log('store pharmacy', payload);
      payload.nonracik.forEach((arr, index) => {
        arr.orderDetailIdx = index;
        arr.amount_given = arr.amount_demanded;
        arr.isODD =
          payload.route !== 'igd' &&
          !payload.is_take_home &&
          !arr.is_extra_drugs &&
          (arr.given_at === 'inap' || arr.given_at === 'continues');
        arr.isRacik = false;
        arr.isNoStock = arr.total;
        arr.isUnconfirmed = true;
        arr.isTakeHome = payload.is_take_home;
        arr.isEditTakeHome = payload.is_take_home;
      });
      payload.racik.forEach((arr, index) => {
        arr.orderDetailIdx = index;
        arr.amount_given = arr.amount_demanded;
        arr.isODD =
          payload.route !== 'igd' &&
          !payload.is_take_home &&
          !arr.is_extra_drugs &&
          (arr.given_at === 'inap' || arr.given_at === 'continues');
        arr.isRacik = true;
        arr.isNoStock = arr.total;
        arr.isUnconfirmed = true;
        arr.isTakeHome = payload.is_take_home;
        arr.isEditTakeHome = payload.is_take_home;
      });
      payload.consumables.forEach((arr, index) => {
        arr.orderDetailIdx = index;
        arr.amount_given = arr.amount_demanded;
      });
      payload.review_receipt = {
        administrative: {
          patient_name: 1,
          age: 1,
          doctor_name: 1,
          receipt_date: 1,
        },
        pharmaceuticals: {
          drug_name: 1,
          strength: 1,
          dosage: 1,
          ob_total: 1,
          rule: 1,
        },
        clinic_aspect: {
          true_indication: 1,
          true_dosage: 1,
          true_time_usage: 1,
          drug_duplication: 1,
          alergic: 1,
          rotd: 1,
        },
        created_by: this.getters.userLoggedIn.id,
      };
      console.log(payload, 'tesa map drug');
      state.detailOrder = payload;
    },
  },
  actions: {},
};
