import axios from 'axios';
import Constant from '@/const';

export const getAccessPublishOpeningBalance = async () => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(
        '/configuration/read/access_permission_publish_opening_balance',
      ),
    );
    return response.data.data;
  } catch (error) {
    return error;
  }
};

export const getConfigApprovalDailyJournal = async () => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat('/configuration/read/journal_approver'),
    );
    return response.data.data;
  } catch (error) {
    return error;
  }
};

export const getConfigApprovalBackdateJournal = async () => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat('/configuration/read/journal_backdate_approver'),
    );
    return response.data.data;
  } catch (error) {
    return error;
  }
};

export const getBenevolentFundsReceivedTypes = async () => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(
        '/configuration/read/benevolent_funds_received_types',
      ),
    );
    return response.data.data;
  } catch (error) {
    return error;
  }
};

export const getBenevolentFundsUsedTypes = async () => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat('/configuration/read/benevolent_funds_used_types'),
    );
    return response.data.data;
  } catch (error) {
    return error;
  }
};

export const getMaxDepth = async () => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat('/configuration/read/max_depth_account'),
    );
    return response.data.data;
  } catch (error) {
    return error;
  }
};

export const getBackdateDay = async () => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat('/configuration/read/finance_journal_backdate'),
    );
    return response.data.data;
  } catch (error) {
    return error;
  }
};

export const getPeriod13 = async () => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat('/accounting/verify'),
    );
    return response.data.data;
  } catch (error) {
    return error;
  }
};

// const getCoa = async (maxDepth) => {
//   try {
//     const response = await axios.get(
//       Constant.apiUrl.concat(`/accounting/coa?depthFilter=${maxDepth}`),
//     );
//     return response.data.data;
//   } catch (error) {
//     return error;
//   }
// };
// {  , getCoa }
